import { AtomButton } from 'atoms/AtomButton'
import { AtomLink } from 'atoms/AtomLink'
import { CanonicalUrlComponent } from 'commons/CanonicalUrlComponent'
import { ContainerFluid } from 'commons/Container'
import { Grid } from 'commons/Grid'
import {
  consoleLog,
  createICSfile,
  formatDate,
  processImagesInHtml,
} from 'commons/utils'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import DynamicComponent, {
  ComponentDataModel,
} from 'components/DynamicComponent/DynamicComponent'
import { PlusIcon } from 'components/Icons/PlusIcon'
import { A2ADataContextProvider } from 'contexts/A2ADataContext'
import { graphql } from 'gatsby'
import {
  StyledAvvisoCategoriaLink,
  StyledAvvisoCategoriaText,
  StyledAvvisoDate,
} from 'pages-style/avvisi.style'
import {
  StyledAccediButtonWrapper,
  StyledAvvisiAddToCalendarWrapper,
  StyledAvvisiCTAWrapper,
  StyledAvvisoBody,
  StyledAvvisoBodyWrapper,
  StyledAvvisoComunicazioneLogo,
  StyledAvvisoImage,
  StyledAvvisoSubtitle,
  StyledAvvisoTitle,
  StyledAvvisoTitleWrapper,
  StyledCategoriaDateWrapper,
} from 'pages-style/avviso.style'
import { Helmet } from 'react-helmet'
import Layout from '../Layout'
import { TestoLibero } from '../components/TestoLibero/TestoLibero'
import { StyledAccediButton } from '../pages-style/avviso.style'
import classNames from 'classnames'
import { ArrowRightIcon } from 'components/Icons/ArrowRightIcon'

export default function PageDefaultComunicazioni(props: PageDataProps) {
  const {
    data: { page },
    pageContext: { title },
  } = props

  if (process.env.NODE_ENV === 'development') {
    consoleLog(
      '%c HERE',
      'background: dodgerblue; color: white; padding: 6px 8px 4px 2px; border-radius: 999px',
      page,
      title
    )
  }

  const hasSimpleHeader = page.field_header_pagina === 'header_without_menu'

  return (
    <A2ADataContextProvider>
      <Layout
        pageTitle={page.field_meta_tags_fe?.title || title}
        simpleHeader={page.field_header_pagina === 'header_without_menu'}
        footerType={page.field_menu_footer_newsite ? 'simple' : 'main'}
      >
        <Helmet
          bodyAttributes={{
            class: classNames(
              'a2a-template-comunicazioni',
              'a2a-navigation-main',
              {
                'a2a-navigation-full': !hasSimpleHeader,
              }
            ),
          }}
        >
          {page.field_meta_tags_fe?.description && (
            <meta
              name="description"
              content={page.field_meta_tags_fe.description}
            />
          )}
        </Helmet>

        <CanonicalUrlComponent url={page?.path?.alias} />

        {page.relationships.field_breadcrumb_content?.field_link_breadcrumb &&
          page.relationships.field_breadcrumb_content?.field_link_breadcrumb
            ?.length > 0 && (
            <Breadcrumbs
              links={[
                ...page.relationships.field_breadcrumb_content
                  .field_link_breadcrumb,
                { title: page.title, uri: page.path.alias || '' },
              ]}
            />
          )}
        <StyledAvvisoTitleWrapper $hasImage={!!page.field_featured_image}>
          <ContainerFluid>
            <Grid
              cols={1}
              colsGap="0"
              rowsGap="24px"
              colsTabletGap="20px"
              colsDesktopGap="28px"
              tabletCols="8fr 4fr"
            >
              <div>
                <StyledCategoriaDateWrapper>
                  {page.relationships.field_categoria_article && (
                    <>
                      {page.relationships.field_categoria_article
                        ?.rh_redirect ??
                      page.relationships.field_categoria_article.path?.alias ? (
                        <StyledAvvisoCategoriaLink
                          to={
                            page.relationships.field_categoria_article
                              ?.rh_redirect ??
                            page.relationships.field_categoria_article.path
                              .alias
                          }
                        >
                          {page.relationships.field_categoria_article?.name ===
                          'News sulla mobilità' ? (
                            <>Notizie</>
                          ) : (
                            <>
                              {page.relationships.field_categoria_article.name}
                            </>
                          )}
                        </StyledAvvisoCategoriaLink>
                      ) : (
                        <StyledAvvisoCategoriaText>
                          {page.relationships.field_categoria_article?.name ===
                          'News sulla mobilità' ? (
                            <>Notizie</>
                          ) : (
                            <>
                              {page.relationships.field_categoria_article.name}
                            </>
                          )}
                        </StyledAvvisoCategoriaText>
                      )}
                    </>
                  )}
                  {page.field_data_pubblicazione && (
                    <StyledAvvisoDate>
                      {formatDate(page.field_data_pubblicazione)}
                    </StyledAvvisoDate>
                  )}
                </StyledCategoriaDateWrapper>
                <StyledAvvisoTitle $weight="600" $color="azure600">
                  {page.title}
                </StyledAvvisoTitle>
                {page.field_sottotitolo && (
                  <StyledAvvisoSubtitle $weight="400" $color="grey700">
                    {page.field_sottotitolo}
                  </StyledAvvisoSubtitle>
                )}
              </div>

              {page.path.alias === '/iniziative-per-te/presentaunamico' ||
              page.path.alias === '/iniziative-per-te/a2a-smile' ? (
                <StyledAccediButtonWrapper>
                  <StyledAccediButton
                    style={{ whiteSpace: 'nowrap' }}
                    styleType="primary"
                    icon={<ArrowRightIcon />}
                    label={
                      page.path.alias === '/iniziative-per-te/a2a-smile'
                        ? 'Accedi ad A2A Smile'
                        : 'Accedi'
                    }
                    to={
                      (page.path.alias === '/iniziative-per-te/presentaunamico'
                        ? process.env.A2A_LOGIN_URL
                        : process.env.A2A_SMILE_LOGIN_URL) || ''
                    }
                  />
                </StyledAccediButtonWrapper>
              ) : (
                page.field_comunicazione_logo && (
                  <StyledAvvisoComunicazioneLogo>
                    <picture>
                      <source
                        srcSet={
                          page.relationships.field_comunicazione_logo?.uri.webp
                        }
                        type="image/webp"
                      />
                      <img
                        src={
                          page.relationships.field_comunicazione_logo?.uri.url
                        }
                        alt={page.field_comunicazione_logo.alt}
                        width={page.field_comunicazione_logo.width || undefined}
                        height={
                          page.field_comunicazione_logo.height || undefined
                        }
                      />
                    </picture>
                  </StyledAvvisoComunicazioneLogo>
                )
              )}
            </Grid>
            {page.field_featured_image && (
              <StyledAvvisoImage>
                <picture>
                  <source
                    srcSet={page.relationships.field_featured_image?.uri.webp}
                    type="image/webp"
                  />
                  <img
                    src={page.relationships.field_featured_image?.uri.url}
                    alt={page.field_featured_image.alt}
                    width={page.field_featured_image.width || undefined}
                    height={page.field_featured_image.height || undefined}
                    loading="lazy"
                  />
                </picture>
              </StyledAvvisoImage>
            )}
          </ContainerFluid>
        </StyledAvvisoTitleWrapper>
        <StyledAvvisoBodyWrapper>
          {page.field_corpo?.processed && (
            <ContainerFluid>
              <StyledAvvisoBody>
                <TestoLibero
                  html={processImagesInHtml(page.field_corpo.processed)}
                />
              </StyledAvvisoBody>
            </ContainerFluid>
          )}
          {page.relationships.field_categoria_article?.name === 'Evento' &&
            page.field_abilita_calendario_evento === true &&
            page.field_data_pubblicazione && (
              <StyledAvvisiAddToCalendarWrapper>
                <ContainerFluid>
                  <AtomButton
                    className={'eventi-add-to-calendar-x431f'}
                    onClick={() => {
                      if (page.field_data_pubblicazione) {
                        createICSfile(
                          page.field_data_pubblicazione,
                          page.field_data_pubblicazione,
                          page.title,
                          null,
                          page.title
                        )
                      }
                    }}
                    label="Aggiungi al calendario"
                    styleType="ghost"
                    icon={<PlusIcon />}
                    iconPosition="right"
                  />
                </ContainerFluid>
              </StyledAvvisiAddToCalendarWrapper>
            )}
          {page.relationships.field_struttura_content &&
            page.relationships.field_struttura_content.map((field, i) => (
              <DynamicComponent
                key={i}
                component={field.__typename}
                data={field}
                related={{
                  siblings: {
                    before:
                      page.relationships.field_struttura_content &&
                      page.relationships.field_struttura_content[i - 1],
                    after:
                      page.relationships.field_struttura_content &&
                      page.relationships.field_struttura_content[i + 1],
                  },
                }}
              />
            ))}
          {page.relationships.field_categoria_article?.name === 'Avvisi' &&
            (page.relationships.field_categoria_article?.rh_redirect ??
              page.relationships.field_categoria_article?.path?.alias) && (
              <ContainerFluid>
                <StyledAvvisiCTAWrapper>
                  <AtomLink
                    label="Torna a tutti gli Avvisi"
                    to={
                      page.relationships.field_categoria_article?.rh_redirect ??
                      page.relationships.field_categoria_article?.path?.alias
                    }
                    styleType="ghost"
                  />
                </StyledAvvisiCTAWrapper>
              </ContainerFluid>
            )}
          {page.relationships.field_categoria_article?.name ===
            'News sulla mobilità' &&
            (page.relationships.field_categoria_article?.rh_redirect ??
              page.relationships.field_categoria_article?.path?.alias) && (
              <ContainerFluid>
                <StyledAvvisiCTAWrapper>
                  <AtomLink
                    label="Torna alle News"
                    to={
                      page.relationships.field_categoria_article?.rh_redirect ??
                      page.relationships.field_categoria_article?.path?.alias
                    }
                    styleType="ghost"
                  />
                </StyledAvvisiCTAWrapper>
              </ContainerFluid>
            )}
        </StyledAvvisoBodyWrapper>
      </Layout>
    </A2ADataContextProvider>
  )
}

interface PageDataProps {
  data: {
    page: PageProps
  }
  pageContext: {
    id: string
    title: string
    drupal_id: string
  }
}

interface PageProps {
  id: string
  drupal_id: string
  drupal_internal__id: number
  internal: {
    type: string
  }
  created: Date
  field_data_pubblicazione: Date | null
  title: string
  field_sottotitolo: string | null
  field_ordine_peso: number
  field_abilita_calendario_evento?: boolean | null
  field_menu_footer_newsite?: boolean | null
  field_header_pagina?: string | null
  field_corpo?: {
    format: string
    processed: string
  }
  path: {
    alias: string
  }
  field_meta_tags_fe?: {
    title: string | null
    description: string | null
  } | null
  field_featured_image?: {
    alt: string
    title: string
    width: number | null
    height: number | null
  }
  field_comunicazione_logo?: {
    alt: string
    title: string
    width: number | null
    height: number | null
  }
  relationships: {
    field_breadcrumb_content?: {
      __typename: string
      id: string
      drupal_id: string
      internal: {
        type: string
      }
      parent_type: string
      parent_field_name: string
      field_link_breadcrumb:
        | {
            title: string
            uri: string
          }[]
        | null
    } | null
    field_featured_image?: {
      uri: {
        url: string
        value: string
        webp: string
      }
    }
    field_comunicazione_logo?: {
      uri: {
        url: string
        value: string
        webp: string
      }
    }
    field_categoria_article?: {
      __typename: string
      name: string
      path: {
        alias: string
      }
      rh_redirect?: string
    }
    field_struttura_content?: ComponentDataModel[]
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    page: a2ANewsiteComunicazioni(id: { eq: $id }) {
      id
      drupal_id
      drupal_internal__id
      title
      path {
        alias
      }
      created(locale: "it_it")
      field_data_pubblicazione(locale: "it_it")
      field_sottotitolo
      field_ordine_peso
      field_abilita_calendario_evento
      field_header_pagina
      field_menu_footer_newsite
      field_meta_tags_fe {
        description
        title
      }
      field_corpo {
        format
        processed
      }
      field_featured_image {
        alt
        title
        width
        height
      }
      field_comunicazione_logo {
        alt
        title
        width
        height
      }
      relationships {
        field_breadcrumb_content {
          ...BreadcrumbsFe
        }
        field_featured_image {
          ...ImageFile
        }
        field_comunicazione_logo {
          ...ImageFile
        }
        field_categoria_article {
          __typename
          name
          path {
            alias
          }
          rh_redirect
        }
        field_struttura_content {
          __typename
          ...ParagraphAnchorMenu
          ...ParagraphBannerFe
          ...ParagraphDoorwaysBannerWrapperFe
          ...ParagraphDoorwaysWrapperFe
          ...ParagraphElevatorPitchFe
          ...ParagraphGalleryFe
          ...ParagraphMarginiFe
          ...ParagraphNoteFe
          ...ParagraphSezioneFe
          ...ParagraphSezioneSeparatoreFe
          ...ParagraphStickyBarFe
          ...ParagraphStoreLocator
          ...ParagraphTestoLiberoFe
        }
      }
    }
  }
`
